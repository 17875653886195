// This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan 
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})

export class FooterComponent implements OnInit {
  isLoaded: boolean = false;
  companyName: string = '';
  companyNit: string = '';
  companyPhone: string = '';
  currentYear: number = new Date().getFullYear();

  ngOnInit(): void {
    this.loadCompanyData();
  }

  loadCompanyData(): void {
    const storedCompanyData = sessionStorage.getItem('company');

    if (storedCompanyData) {
      const company = JSON.parse(storedCompanyData);
      this.companyName = company.name || '';
      this.companyNit = company.nit || '';
      this.companyPhone = company.phone || '';
    }
    if(this.companyName && this.companyNit && this.companyPhone){
      this.isLoaded = true;
    }
  }
}
