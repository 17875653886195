<!-- This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan -->
<footer class="footer" *ngIf="isLoaded">
    <div class="footer-content">
        <div class="footer-info">
            <h2>{{ companyName }}</h2>
            <p>NIT: {{ companyNit }}</p>
            <p>Teléfono: {{ companyPhone }}</p>
        </div>
        <div class="footer-links">
            <a href="/privacy-policy">Política de Privacidad</a>
            <a href="/terms-and-conditions">Términos y Condiciones</a>
        </div>
    </div>
    <div class="footer-bottom">
        <p>&copy; {{ currentYear }} {{ companyName }}. Todos los derechos reservados.</p>
    </div>
</footer>